
class RegisterCustomer {
  email: string
  postalCode: string
  lastFourOfSsn: string
  dateOfBirth: string
}

class CompleteRegistration {
  username: string
  password: string
  registrationToken: string
}

class UserInfoDto {
  rId: number
  oktaId: number
  username: string
  email: string
  masterMemberNumber: string
  programCode: string
  firstName: string
  lastName: string
  phoneNumber: string
  lastLogin: Date
}

export { RegisterCustomer, CompleteRegistration as CompleteRegistrationDto, UserInfoDto }