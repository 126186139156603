import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { AuthGuard } from '../../src/security/services/auth'

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('../app/home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthGuard],
    data: {
      title: 'Home',
      breadcrumb: 'commons.breadcrumbs.home'
    }
  },
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full',
    data: {
      title: 'Login'
    }
  },
  {
    path: 'auth',
    loadChildren: () => import('../app/authentication/authentication.module').then((m) => m.LoginPageModule),
    data: {
      title: 'Login'
    }
  },
  {
    path: 'card',
    loadChildren: () => import('../app/card/card.module').then((m) => m.CardPageModule),
    canActivate: [AuthGuard],
    data: {
      title: 'Card'
    }
  },
  {
    path: 'account',
    loadChildren: () => import('../app/account/account.module').then((m) => m.AccountPageModule),
    canActivate: [AuthGuard],
    data: {
      title: 'Account Details'
    }
  },
  {
    path: 'statement',
    loadChildren: () => import('../app/statement/statement.module').then((m) => m.StatementPageModule),
    canActivate: [AuthGuard],
    data: {
      title: 'Statements'
    }
  },
  {
    path: 'bank-transfer-enrollments',
    loadChildren: () => import('../app/bank-transfer-enrollments/bank-transfer-enrollments.module').then((m) => m.BankTransferEnrollmentsModule),
    canActivate: [AuthGuard],
    data: {
      title: 'Bank Transfer Enrollment'
    }
  },
  {
    path: 'healthcheck',
    loadChildren: () => import('@sightline/health-check').then((m) => m.HealthCheckPageModule),
  },
  {
    path: '**',
    redirectTo: 'home',
    data: {
      title: 'Home',
      breadcrumb: 'commons.breadcrumbs.home'
    }
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
