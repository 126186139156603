<ion-header gaCategory="add_card_to_wallet">
  <ion-item lines="none" class="header-item">
    <ion-button slot="end" class="header-buttons" (click)="dismissModal()">
      <ion-icon name="close-outline"></ion-icon>
    </ion-button>
  </ion-item>
  <ion-item lines="none" class="title-item">
    <ion-label>
      {{ "commons.link2wallet.title" | translate }}
    </ion-label>
  </ion-item>
</ion-header>
<ion-content gaCategory="link2wallet" *ngIf="account">

  <div class="modal-info">
    <ion-row>
      <ion-col>
        <div class="account-card-image"></div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <div class="modal-ion-item">
          <ion-label class="modal-label">
            {{
            "commons.link2wallet.message" | translate: { programName: account.programName, walletType: walletType }
            }}
          </ion-label>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <a [href]="tokenlink" target="_blank" *ngIf="tokenlink" gaEvent="link_to_wallet"
          class="link-to-wallet-anchor">
          <ion-button class="action-button" color="primary" expand="block">{{ 'commons.link2wallet.link2wallet' |
            translate }}</ion-button>
        </a>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button class="action-button" expand="block" color="light" (click)="dismissModal()">
          {{ "commons.link2wallet.cancel" | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
</ion-content>