/* eslint-disable @typescript-eslint/no-unused-vars-experimental */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AuthnTransaction, OktaAuth, UserClaims } from '@okta/okta-auth-js'
import { EventEmitter, Inject, Injectable, InjectionToken, Optional } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { OktaProvider, OktaFactorType } from '../../models'
import { CacheService } from '../../../../src/commons/services/business/cache.service'

export const OKTA_CONFIG = new InjectionToken<IOktaConfig>('OktaConfig')

export interface IOktaConfig {
  clientId: string
  issuer: string
  scopes: string[]
}

export const FREQUENCY = 1000 // 1 sec
const MAX_IDLE_TIME_BEFORE_OPEN_DIALOG = 780 //13 minutes in seconds
const COUNTDOWN_SECONDS = 120

@Injectable({
  providedIn: 'root',
})
export class AuthWrapperService {
  private _config: IOktaConfig
  private _checkIntervalSession

  private S_SLP_SESSION_COUNTDOWN_TIME = 'sslp-sct'
  private S_SLP_IDLE_TIME = 'sslp-it'
  private S_SLP_IDLE_MODAL_IS_ACTIVE = 'sslp-imia'
  private S_SLP_CONTDOWN_TIME = 'sslp-ct'

  public onInactive = new EventEmitter()
  public onActive = new EventEmitter()
  public countDownToLogout: string

  private nonce: string

  get idleTimeToOpenModal(): number {
    return Number.parseInt(localStorage.getItem(this.S_SLP_IDLE_TIME))
  }

  set idleTimeToOpenModal(value: number) {
    localStorage.setItem(this.S_SLP_IDLE_TIME, value.toString())
  }

  get idleModalIsActive(): string {
    return localStorage.getItem(this.S_SLP_IDLE_MODAL_IS_ACTIVE)
  }

  set idleModalIsActive(value: string) {
    localStorage.setItem(this.S_SLP_IDLE_MODAL_IS_ACTIVE, value)
  }

  get isContdownTimeStarted(): string {
    return localStorage.getItem(this.S_SLP_CONTDOWN_TIME)
  }

  set isContdownTimeStarted(value: string) {
    localStorage.setItem(this.S_SLP_CONTDOWN_TIME, value)
  }

  private _authClient: OktaAuth

  get authClient(): OktaAuth {
    return this._authClient
  }

  set authClient(value: OktaAuth) {
    this._authClient = value
  }

  constructor(private httpClient: HttpClient, private cacheService: CacheService, @Inject(OKTA_CONFIG) @Optional() config?: IOktaConfig) {
    this._config = config
    this.authClient = new OktaAuth(this._config)
  }

  public async login(username: string, password: string): Promise<AuthnTransaction> {
    try {
      await this.clearAnyExistingSession()
      const response = await this.authClient.signIn({
        username: username,
        password: password,
      })
      if (response.status === 'SUCCESS') {
        await this.initSession(response)
      }

      return response
    } catch (error) {
      console.log(error)
    }
  }

  async clearAnyExistingSession() {
    const session = await this.authClient.session.get()
    if (session && session.status !== 'INACTIVE') {
      await this.authClient.revokeAccessToken()
      await this.authClient.closeSession()
    }
    this.cacheService.clearLocalStorageCache()
    this.clearLocalStorageKeys()
  }

  public requestMfaCode(authTransaction: AuthnTransaction, factorType: OktaFactorType, provider: OktaProvider, oktaCustomDomain: string): Promise<any> {
    const stateToken = (authTransaction as any).data.stateToken
    const authData = (authTransaction as any).data._embedded
    if (authData && authData.factors && authData.factors.filter(q => q.factorType == factorType && q.provider == provider)?.length > 0) {
      const factor = authData.factors.find(q => q.factorType == factorType && q.provider == provider)
      const url = this.getOktaCustomDomain(factor._links.verify.href, oktaCustomDomain)
      const requestBody = { ...factor }
      requestBody.stateToken = stateToken
      Reflect.deleteProperty(requestBody, '_links')
      return this.httpClient.post(url, requestBody).toPromise()
    }
  }

  // only works for email and sms
  public resendMfaCode(mfaCodeResponse: any, oktaCustomDomain: string): Promise<any> {
    const stateToken = mfaCodeResponse.stateToken
    if (mfaCodeResponse != null && mfaCodeResponse._links && mfaCodeResponse._links.resend &&
      mfaCodeResponse._links.resend.filter(q => q.name == OktaFactorType.Email || q.name == OktaFactorType.Sms).length > 0) {

      const factor = mfaCodeResponse._embedded.factor
      const resendUrl = mfaCodeResponse._links.resend.find(q => q.name == OktaFactorType.Email || q.name == OktaFactorType.Sms).href
      const url = this.getOktaCustomDomain(resendUrl, oktaCustomDomain)
      const requestBody = { ...factor }
      requestBody.stateToken = stateToken
      Reflect.deleteProperty(requestBody, '_links')
      return this.httpClient.post(url, requestBody).toPromise()
    }
  }

  public getOktaCustomDomain(oktaUrl: string, customBrandOktaUrl: string): string {
    if (customBrandOktaUrl) {
      const path = '/api/' + oktaUrl.split('/api')[1]
      return customBrandOktaUrl + path
    } else {
      return oktaUrl
    }
  }

  public async activateMfaCode(mfaCodeResponse: any, passCode: string): Promise<boolean> {
    try {
      const url = mfaCodeResponse._links.next.href
      const requestBody = { passCode: passCode, stateToken: mfaCodeResponse.stateToken }
      const response: any = await this.httpClient.post(url, requestBody).toPromise()
      if (response.status === 'SUCCESS') {
        await this.initSession(response)
        return Promise.resolve(true)
      } else {
        return Promise.resolve(false)
      }
    }
    catch (error) {
      console.log(error)
      return Promise.resolve(false)
    }
  }

  async initSession(authTransaction: AuthnTransaction): Promise<void> {
    const tokens = await this.authClient.token.getWithoutPrompt({
      clientId: this._config.clientId,
      responseType: ['id_token', 'token'],
      scopes: this._config.scopes,
      sessionToken: authTransaction.sessionToken,
      nonce: this.nonce,
      redirectUri: window.location.origin + '/login/callback',
    })
    this.authClient.tokenManager.setTokens(tokens.tokens)
    this.start()
  }

  clearLocalStorageKeys(): void {
    Object.keys(localStorage)
      .filter((x) => x.startsWith('sslp'))
      .forEach((x) => localStorage.removeItem(x))
  }

  public async logOut(): Promise<void> {
    this.end()

    if (!(await this.isTheSameSession())) {
      localStorage.removeItem('okta-cache-storage')
      localStorage.removeItem('okta-token-storage')
      window.location.href = window.location.origin + '/auth/login'
      return
    }
    await this.authClient.signOut({
      revokeAccessToken: true,
      clearTokensBeforeRedirect: true,
      postLogoutRedirectUri: document.baseURI,
    })
  }

  public getAccessToken(): string {
    const token = localStorage.getItem('okta-token-storage')
    if (!token)
      return ''

    const tokenParsed = JSON.parse(token)
    return tokenParsed.accessToken.accessToken
  }

  public getJwtTokenParsed(): any {
    const token = this.getAccessToken()
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const json = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    return JSON.parse(json)
  }

  public async getUser(): Promise<UserClaims> {
    return this.authClient.getUser()
  }

  public async isAuthenticated(): Promise<boolean> {
    return await this.authClient.isAuthenticated()
  }

  //this is a workaround because okta uses cookies to handle the session
  //ref: https://github.com/okta/okta-auth-js#third-party-cookies
  public async isTheSameSession() {
    try {
      const token = this.getJwtTokenParsed()
      const session = await this.authClient.session.get()

      if (session && (session as any).login !== token.sub)
        return false

      return true
    }
    catch {
      return false
    }
  }

  public async refreshToken(): Promise<void> {
    if (!(await this.isTheSameSession()))
      return

    await this.authClient.session.refresh()
    const tokens = await this.authClient.token.getWithoutPrompt({
      redirectUri: window.location.origin + '/login/callback',
    })
    this.authClient.tokenManager.setTokens(tokens.tokens)
    this.start()
  }

  public start(): void {
    if (!this._checkIntervalSession) {
      this.resetIdleTime()
      this._checkIntervalSession = setInterval(() => this.checkSessionInactivity(), FREQUENCY)
      this.resetIdleTimeOnUserAction()
    }
  }

  public checkSessionInactivity(): void {
    if (new Date(this.idleTimeToOpenModal) < new Date() || this.idleModalIsActive) {
      if (!this.isContdownTimeStarted) {
        localStorage.setItem(this.S_SLP_SESSION_COUNTDOWN_TIME, new Date().getTime().toString())
        this.idleModalIsActive = 'true'
        this.isContdownTimeStarted = 'true'
      }

      const timer = this.getTimer()
      this.countDownToLogout = this.getCountDownFormatted(timer)

      if (timer <= 0) {
        sessionStorage.setItem('showTimeoutMessage', 'true')
        this.logOut()
      }

      this.onInactive.next()
    } else {
      this.onActive.next()
    }
  }

  public renewIdleSession(): void {
    this.idleModalIsActive = ''
    this.isContdownTimeStarted = ''
  }

  public getTimer(): number {
    const timeout = new Date(Number.parseInt(localStorage.getItem(this.S_SLP_SESSION_COUNTDOWN_TIME)))
    timeout.setSeconds(timeout.getSeconds() + COUNTDOWN_SECONDS)
    let timer = Math.round(new Date(timeout.getTime() - new Date().getTime()).getTime() / 1000)
    if (timer < 0 || isNaN(timer))
      timer = 0

    return timer
  }

  public getCountDownFormatted(timer: number): string {
    const minutes = this.getMinutesFormatted(timer)
    const seconds = this.getSecondsFormatted(timer)
    return `${minutes} : ${seconds}`
  }

  private getMinutesFormatted(timer: number): string {
    let minutes
    minutes = parseInt((timer / 60).toString(), 10)
    minutes = minutes < 10 ? '0' + minutes : minutes
    return minutes
  }

  private getSecondsFormatted(timer: number): string {
    let seconds
    seconds = parseInt((timer % 60).toString(), 10)
    seconds = seconds < 10 ? '0' + seconds : seconds
    return seconds
  }

  public resetIdleTime = (): void => {
    const currentDate = new Date()
    currentDate.setSeconds(currentDate.getSeconds() + MAX_IDLE_TIME_BEFORE_OPEN_DIALOG)
    this.idleTimeToOpenModal = currentDate.getTime()
  }

  private resetIdleTimeOnUserAction() {
    window.addEventListener('keypress', this.resetIdleTime)
    window.addEventListener('click', this.resetIdleTime)
  }

  public end(): void {
    this.resetIdleTime()
    clearInterval(this._checkIntervalSession)
  }
}
