
export class AddUserToOktaGroupRequestDto {
  oktaGroupName: string
}

export class EnrollMfaRequestDto {
  type: MfaType
}

export class UserPreferenceDto {
  userPreferenceKey: UserPreferenceKey
  userPreferenceValue: string
}

export class VerifyMfaCodeDto {
  passCode: string
}

export enum UserPreferenceKey {
  MFA_PREFERRED_METHOD = 'MFA_PREFERRED_METHOD'
}

export class ActivateMfaRequestDto {
  type: MfaType
  oktaUserId: string
  factorId: string
  passCode: string
}

export class FactorEnrolledResponseDto {
  oktaUserId: string
  enrolledFactorId: string
  qrCodeImageUrl: string
}

export class UserFactorDto {
  id: string
  provider: OktaProvider
  status: string
  factorType: OktaFactorType
  factorData: any
}

export enum OktaProvider {
  Okta = 'OKTA',
  Rsa = 'RSA',
  Fido = 'FIDO',
  Google = 'GOOGLE',
  Symantec = 'SYMANTEC',
  Duo = 'DUO',
  Yubico = 'YUBICO',
  Custom = 'CUSTOM',
  Apple = 'APPLE'
}

export enum OktaFactorType {
  Call = 'call',
  Email = 'email',
  Hotp = 'hotp',
  Push = 'push',
  Question = 'question',
  Sms = 'sms',
  TokenHardware = 'token:hardware',
  TokenHotp = 'token:hotp',
  TokenSoftwareTotp = 'token:software:totp',
  Token = 'token',
  U2f = 'u2f',
  Web = 'web',
  Webauthn = 'webauthn'
}

export enum MfaType {
  None = '',
  Email = 'Email',
  Sms = 'Sms',
  GoogleAuthenticator = 'GoogleAuthenticator',
  OktaVerify = 'OktaVerify'
}

export enum OktaGroups {
  //Customer Portal
  CrbMfa = 'CRB_MFA'
}
