/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { MatDialog } from '@angular/material/dialog'
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { IonPopover, ModalController } from '@ionic/angular'
import { CacheService } from '../services/business/cache.service'
import { UtilsService } from '../services/business/utils.service'
import { MyProfileComponent } from './my-profile/my-profile.component'
import { ChangePasswordComponent } from './change-password/change-password.component'
import { NavigationService, SlpSubMenu } from '../services/business/navigation.service'
import { AuthWrapperService } from '../../../src/security/services/auth'
import { UserInfoDto } from '../../../src/security/models'
import { GoogleAnalyticsEvents } from '../enums/google-analytics-events.enum'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  isProfileOpen = false
  alert: any

  brandedLogoUrl: string
  brandedGlobalHeaderColorIsBrightness: boolean

  @ViewChild('profilePopover') profilePopover: IonPopover

  user: UserInfoDto

  constructor(
    public dialog: MatDialog,
    public utils: UtilsService,
    public authWrapperService: AuthWrapperService,
    public modalController: ModalController,
    public cacheService: CacheService,
    public navigationService: NavigationService
  ) {
  }

  promptLogout(templateRef: TemplateRef<any>): void {
    this.alert = this.dialog.open(templateRef, { disableClose: true })
  }

  closePrompt(): void {
    if (this.alert)
      this.alert.close()
  }

  logout(): void {
    this.alert?.close()
    void this.cacheService.clearLocalStorageCache()
    void this.authWrapperService.logOut()
  }

  async ngOnInit(): Promise<void> {
    this.brandedGlobalHeaderColorIsBrightness = UtilsService.brandedGlobalHeaderColorIsBrightness
    this.user = await this.cacheService.getUserInfo()
  }

  public async viewProfile(): Promise<void> {
    void this.profilePopover.dismiss()
    const modal = await this.modalController.create({
      component: MyProfileComponent,
      cssClass: 'common-modal'
    })
    return modal.present()
  }

  public async changePassword(): Promise<void> {
    void this.profilePopover.dismiss()
    const modal = await this.modalController.create({
      component: ChangePasswordComponent,
      cssClass: 'common-modal'
    })
    return modal.present()
  }

  public submenuClicked(submenuPopover: IonPopover, child: SlpSubMenu): void {
    this.navigationService.menuClicked(child)
    if (submenuPopover)
      void submenuPopover.dismiss()
  }

  public get googleAnalyticsEvents(): typeof GoogleAnalyticsEvents {
    return GoogleAnalyticsEvents
  }
}
