import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
  providedIn: 'root'
})
export class LanguageTranslationService {

  availableLanguages = ['en', 'es']

  constructor(private translateService: TranslateService) {
  }

  setLang(): void {
    this.translateService.currentLang = ''
    const lang = this.translateService.getBrowserLang()
    if (this.availableLanguages.indexOf(lang) >= 0) {
      this.translateService.use(lang)
    } else {
      this.translateService.use('en')
    }
  }
}