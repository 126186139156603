<div class="content">
  <p>{{ "commons.idleMessage.question" | translate }}</p>
  <p>{{ "commons.idleMessage.message" | translate }}</p>
  <p>{{ getTime() }}</p>
  <div>
    <div class="action">
      <ion-button class="ion-margin" expand="block" (click)="renewSession()">
        {{ "commons.idleMessage.idleButton" | translate }}
      </ion-button>
      <ion-button class="ion-margin" expand="block" color="medium" (click)="logOut()">
        {{ "commons.menu.logOut" | translate }}
      </ion-button>
    </div>
  </div>
</div>