import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value: string): string {
    // Remove any non-digit characters from the value
    const cleanedValue = value.replace(/\D/g, '')

    // Apply the phone number mask
    return cleanedValue.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  }
}
