import { Component } from '@angular/core'
import { MenuController } from '@ionic/angular'
import { NavigationService } from '../services/business/navigation.service'

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent {

  constructor(
    public navigationService: NavigationService,
    public menu: MenuController
  ) {
    void navigationService.loadMenus()
  }

}