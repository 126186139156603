import { Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { GoogleAnalyticsService } from 'ngx-google-analytics'

@Injectable()
export class TitleService {

    constructor(
        private readonly titleService: Title,
        private router: Router,
        private gaService: GoogleAnalyticsService
    )
    {
        
    }

    public getTitle(titleText: string) : string {
        titleText = this.checkTranslatedText(titleText)

        if(titleText) {
            return `${titleText}`
        }

        return ''
    }

    public setTitle(titleText: string, appendText = true) : void {
        const title = appendText ? this.getTitle(titleText) : titleText
        this.titleService.setTitle(title)
        this.gaService?.pageView(this.router.url, title)
    }

    private checkTranslatedText(translate: string): string {
        if(translate.indexOf('.') > 0){
          const keyIdentifier = translate.split('.').pop()
          //formatting the title in case the 
          return (keyIdentifier.charAt(0).toUpperCase() + keyIdentifier.slice(1)).replace(/([a-z])([A-Z])/g, '$1 $2')
        }
    
        return translate
    }
}