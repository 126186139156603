import { Injectable } from '@angular/core'
import { Account } from 'src/app/account/models/account.model'
import { Card } from 'src/app/card/activate/models/card.model'
import { FileDto } from '../api/models/file.model'
import { UserApiService } from '../../../../src/security/services/users/userApi.service'
import { UserInfoDto } from 'src/security/models'
import { CRBConfigDto, CRBConfigSettingDto } from '../api/models/configuration.model'

@Injectable({
  providedIn: 'root',
})
export class CacheService {

  private SLP_USER_INFO = 'slp-user-info'
  private SLP_CARD_REFERENCE_NUMBER = 'slp-card-reference-number'
  private SLP_SELECTED_ACCOUNT = 'slp-selected-account'
  private SLP_CARDS = 'slp-cards'
  private SLP_STATEMENTS_PAGE = 'slp-statements-page'

  //this settings are always loaded when page refresh
  private SITE_CRB_CONFIG = 'crb-config'
  private SITE_BRAND_FILES = 'brand-files'
  private SITE_BRAND_CONFIG_SETTINGS = 'brand-config-settings'

  constructor(private userApiService: UserApiService) { }

  public async getUserInfo(): Promise<UserInfoDto> {
    let userInfo = localStorage.getItem(this.SLP_USER_INFO)
    if (userInfo == null) {
      userInfo = JSON.stringify(await this.userApiService.getUserInfo())
      localStorage.setItem(this.SLP_USER_INFO, userInfo)
    }
    return JSON.parse(userInfo) as UserInfoDto
  }

  public getSelectedAccount(): Account {
    const selectedAccount = localStorage.getItem(this.SLP_SELECTED_ACCOUNT)
    if (selectedAccount === 'undefined') {
      return null
    }
    return JSON.parse(selectedAccount) as Account
  }

  public setSelectedAccount(selectedAccount: Account): void {
    const jsonAccount = JSON.stringify(selectedAccount)
    localStorage.setItem(this.SLP_SELECTED_ACCOUNT, jsonAccount)
  }

  public getCards(): Card[] {
    const cards = localStorage.getItem(this.SLP_CARDS)
    if (cards === 'undefined') {
      return null as Card[]
    }
    return JSON.parse(cards) as Card[]
  }

  public setCards(cards: Card[]): void {
    const jsonAccount = JSON.stringify(cards)
    localStorage.setItem(this.SLP_CARDS, jsonAccount)
  }

  public getCardReferenceNumber(): string {
    const cardReferenceNumber = localStorage.getItem(this.SLP_CARD_REFERENCE_NUMBER)
    if (cardReferenceNumber === 'undefined') {
      return null
    }
    return cardReferenceNumber
  }

  public setCardReferenceNumber(cardReferenceNumber: string): void {
    localStorage.setItem(this.SLP_CARD_REFERENCE_NUMBER, cardReferenceNumber)
  }

  public setBrandFiles(files: FileDto[]): void {
    const jsonAccount = JSON.stringify(files)
    localStorage.setItem(this.SITE_BRAND_FILES, jsonAccount)
  }

  public getBrandFiles(): FileDto[] {
    const files = localStorage.getItem(this.SITE_BRAND_FILES)
    if (files === 'undefined') {
      return null as FileDto[]
    }
    return JSON.parse(files) as FileDto[]
  }

  public setCRBConfig(crbConfig: CRBConfigDto): void {
    const jsonCRBConfig = JSON.stringify(crbConfig)
    localStorage.setItem(this.SITE_CRB_CONFIG, jsonCRBConfig)
  }

  public getCRBConfig(): CRBConfigDto {
    const crbConfig = localStorage.getItem(this.SITE_CRB_CONFIG)
    if (crbConfig === 'undefined') {
      return null as CRBConfigDto
    }
    return JSON.parse(crbConfig) as CRBConfigDto
  }

  public getBrandConfigSettings(): CRBConfigSettingDto[] {
    const crbConfigSettings = localStorage.getItem(this.SITE_BRAND_CONFIG_SETTINGS)
    if (crbConfigSettings === 'undefined') {
      return null as CRBConfigSettingDto[]
    }
    return JSON.parse(crbConfigSettings) as CRBConfigSettingDto[]
  }

  public setBrandConfigSettings(brandConfigSettings: CRBConfigSettingDto[]): void {
    const jsonCRBConfig = JSON.stringify(brandConfigSettings)
    localStorage.setItem(this.SITE_BRAND_CONFIG_SETTINGS, jsonCRBConfig)
  }

  public setStatementsCache = (
    selectedDateRange: string
  ): void => {
    localStorage.setItem(
      `${this.SLP_STATEMENTS_PAGE}`,
      JSON.stringify({
        selectedDateRange: selectedDateRange
      })
    )
  }

  public getStatementsCache(): string {
    const lastSearch = localStorage.getItem(`${this.SLP_STATEMENTS_PAGE}`)
    if (lastSearch != null) return JSON.parse(lastSearch)['selectedDateRange'] as string
    else return null
  }

  public clearLocalStorageCache = (): void =>
    Object.keys(localStorage)
      .filter((x) => x.startsWith('slp'))
      .forEach((x) => localStorage.removeItem(x))
}
