<div class="header">
  <div class="header-text">
    {{ headerText }}
  </div>
</div>
<div class="content">
  <div class="content-text">
    {{ messageText }}
  </div>
  <div class="action">
    <ion-button *ngFor="let button of actionButtons" (click)="executeAction(button)" [ngClass]="button.cssClass"
      [color]="button.color ? button.color : 'primary'" class="action-button">
      {{ button.text }}
    </ion-button>
  </div>
</div>