<div class="loading-overlay animated fadeIn">
  <div class="list-placeholder table-responsive table--no-card" *ngIf="type === 1">
    <table class="table table-borderless table-striped table-earning primary">
      <tbody>
        <tr>
          <td>
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td>
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td>
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td class="d-none d-md-table-cell">
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td class="d-none d-md-table-cell">
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td class="d-none d-md-table-cell">
            <div class="placeholder-content">&nbsp;</div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td>
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td>
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td class="d-none d-md-table-cell">
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td class="d-none d-md-table-cell">
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td class="d-none d-md-table-cell">
            <div class="placeholder-content">&nbsp;</div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td>
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td>
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td class="d-none d-md-table-cell">
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td class="d-none d-md-table-cell">
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td class="d-none d-md-table-cell">
            <div class="placeholder-content">&nbsp;</div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td>
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td>
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td class="d-none d-md-table-cell">
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td class="d-none d-md-table-cell">
            <div class="placeholder-content">&nbsp;</div>
          </td>
          <td class="d-none d-md-table-cell">
            <div class="placeholder-content">&nbsp;</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="form-placeholder card" *ngIf="type === 2">
    <div class="card-header primary">&nbsp;</div>
    <div class="card-body card-block">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-control-label">
              <div class="placeholder-content">&nbsp;</div>
            </label>
            <div class="placeholder-content thick">&nbsp;</div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="form-control-label">
              <div class="placeholder-content">&nbsp;</div>
            </label>
            <div class="placeholder-content thick">&nbsp;</div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="form-control-label">
              <div class="placeholder-content">&nbsp;</div>
            </label>
            <div class="placeholder-content thick">&nbsp;</div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label class="form-control-label">
              <div class="placeholder-content">&nbsp;</div>
            </label>
            <div class="placeholder-content thick">&nbsp;</div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-control-label">
              <div class="placeholder-content">&nbsp;</div>
            </label>
            <div class="placeholder-content thick">&nbsp;</div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-control-label">
              <div class="placeholder-content">&nbsp;</div>
            </label>
            <div class="placeholder-content thick">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-placeholder" *ngIf="type === 3">
    <div class="card-body card-block padding-40">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-control-label">
              <div class="placeholder-content">&nbsp;</div>
            </label>
            <div class="placeholder-content thick">&nbsp;</div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="form-group">
            <label class="form-control-label">
              <div class="placeholder-content">&nbsp;</div>
            </label>
            <div class="placeholder-content thick">&nbsp;</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-control-label">
              <div class="placeholder-content">&nbsp;</div>
            </label>
            <div class="placeholder-content thick">&nbsp;</div>
          </div>
        </div>

        <div class="col-md-9">
          <div class="form-group">
            <label class="form-control-label">
              <div class="placeholder-content">&nbsp;</div>
            </label>
            <div class="placeholder-content thick">&nbsp;</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-control-label">
              <div class="placeholder-content">&nbsp;</div>
            </label>
            <div class="placeholder-content thick">&nbsp;</div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="form-group">
            <label class="form-control-label">
              <div class="placeholder-content">&nbsp;</div>
            </label>
            <div class="placeholder-content thick">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>