export const environment = {
  appVersion: '#{{VERSION}}#',
  production: true,
  //api:
  apiUrl: '#{{API_URL}}#',
  apiVersion: '#{{API_VERSION}}#',
  //Okta:
  clientId: '#{{OKTA_CLIENT_ID}}#',
  issuer: '#{{OKTA_ISSUER}}#',
  scopes: ['#{{OKTA_SCOPES}}#'],
  pkce: true,

  // Google Analytics
  measurementId: '#{{GA_MEASUREMENT_ID}}#',

  //Pace Chat
  spaceChat: '#{{SPACE_CHAT_URL}}#',
  spaceAuthenticatedChat: '#{{AUTHENTICATED_SPACE_CHAT_URL}}#',

  //Slider
  sliderDelay: 5000
}
