<ion-header gaCategory="contact_us_header">
  <ion-item lines="none" class="header-item">
    <ion-button slot="end" class="header-buttons" (click)="dismissModal()">
      <ion-icon name="close-outline"></ion-icon>
    </ion-button>
  </ion-item>
  <ion-item lines="none" class="title-item">
    <ion-label>
      {{ "commons.contactUs.title" | translate }}
    </ion-label>
  </ion-item>
</ion-header>
<ion-content gaCategory="contact_us">

  <div class="modal-info">
    <ion-row>
      <ion-col>
        <ion-item lines="none">
          <ion-label class="subtitle">
            {{ "commons.contactUs.subtitle" | translate }}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-item lines="none" class="modal-ion-item">
          <ion-label class="modal-label">
            {{ "commons.contactUs.message" | translate: { phoneNumber: phoneNumber} }}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </div>

</ion-content>