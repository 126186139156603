/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unused-vars-experimental */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { DatePipe } from '@angular/common'
import { Component, HostListener, OnInit } from '@angular/core'
import { CacheService } from '../services/business/cache.service'
import { AuthWrapperService } from '../../../src/security/services/auth'

@Component({
  selector: 'tab-monitor',
  templateUrl: './tab-monitor.component.html',
  styleUrls: ['./tab-monitor.component.scss'],
})
export class TabMonitorComponent implements OnInit {

  private SLP_TABS = 'slp-tabs'
  private SLP_BASE_AUTH_URL = '/auth/'
  private sessionStorageWasEmpty = false

  constructor(private datePipe: DatePipe,
    private cacheService: CacheService,
    private authWrapperService: AuthWrapperService) { }

  ngOnInit() {
    this.monitor()
  }

  private monitor() : void {
    const tabId = this.getCurrentTabId()
    let tabs = this.getTabs()

    if(this.isFirstPortalTabOpening(tabs)){
      tabs = []

      // Explaining the condition 'sessionStorageWasEmpty'
      // When the user press F5 with only one Portal tag, the browser calls 'window:unload'
      // event and the tab id will be removed from 'SLP_TABS' from local storage and considering it was only one tab
      // the 'tabs.length' should be equal to 0 (what is in the end of the day false because we did not remove anything)
      // However, the session storage would still have 'tabId' and because of that it cannot be considered as
      // "first portal tab opening"
      if(!this.isAnyAuthPage() && this.sessionStorageWasEmpty){
        this.logOut()
        return
      }
    }

    if(this.isNewTab(tabs, tabId)) {
      this.addNewTab(tabs, tabId)
    }
  }

  private logOut() {
    this.cacheService.clearLocalStorageCache()
    void this.authWrapperService.logOut()
  }

  private isNewTab(tabs : PortalTab[], tabId : string) : boolean {
    const index = tabs.findIndex(x => x.id === tabId)
    return index === -1
  }

  private addNewTab(tabs : PortalTab[], tabId : string) : void {
    const currentTab = { id : tabId } as PortalTab
    tabs.push(currentTab)
    localStorage.setItem(this.SLP_TABS, JSON.stringify(tabs))
  }

  private getCurrentTabId() : string {
    let tabId = sessionStorage.tabId

    if(tabId === undefined) {
      tabId = this.generateIdBasedOnDate()
      sessionStorage.tabId = tabId
      this.sessionStorageWasEmpty = true
    }

    return tabId
  }

  private generateIdBasedOnDate() : string {
    return this.datePipe.transform(new Date(), 'yyyyMMddHHmmssSSS')
  }

  private getTabs() : PortalTab[]{
    return JSON.parse(localStorage.getItem(this.SLP_TABS)) as PortalTab[]
  }

  private isFirstPortalTabOpening(tabs? : PortalTab[]) : boolean {
    return tabs == null || tabs.length == 0
  }

  private isAnyAuthPage() : boolean {
    return window.location.pathname.startsWith(this.SLP_BASE_AUTH_URL)
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    this.removeTab()
  }

  private removeTab() : void {
    const tabs = this.getTabs()
    const tabId = sessionStorage.tabId

    const index = tabs.findIndex(x => x.id === tabId)

    if(index !== -1) {
      tabs.splice(index, 1)
      localStorage.setItem(this.SLP_TABS, JSON.stringify(tabs))
    }
  }
}

class PortalTab {
  id : string;
}
