enum PasswordRuleResultType {
    Failure = 'Failure',
    NIL = 'NIL',            // Imput was missing; rule not applied
    Success = 'Success'
}

interface IAppliedPasswordRule {
    id: string
    description: string
    label: string
    result: PasswordRuleResultType
    tooltip?: string
}

interface IPasswordRule {
    id: string
    description?: string
    evalWhenNil?: boolean   // If true, the rule is tested even when the input is undefined, null, or empty
    tooltip?: string
    translate?: boolean     // If true, label and tooltip are translation keys
}

interface ICustomPasswordRule extends IPasswordRule {
    custom: (PasswordRuleContext) => boolean | undefined
}

interface IPatternPasswordRule extends IPasswordRule {
    pattern: PasswordPattern
}

interface IPatternCollectionPasswordRule extends IPasswordRule {
    patterns: PasswordPattern[]
}

type PasswordPattern = RegExp | Template
type PasswordRule = ICustomPasswordRule | IPatternPasswordRule | IPatternCollectionPasswordRule
type PasswordRuleContext = Record<string, any> & { password: string; retypePassword?: string }
type Template = string      // Templates are strings in the form of "Your name is {name}."

export {
    IAppliedPasswordRule,
    ICustomPasswordRule,
    IPatternPasswordRule,
    IPatternCollectionPasswordRule,
    PasswordRule,
    PasswordRuleContext,
    PasswordRuleResultType,
    Template
}
