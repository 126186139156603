/* eslint-disable sonarjs/cognitive-complexity */
import { Injectable } from '@angular/core'
import { Address } from '../../../app/account/models/address.model'
import { ApiService } from '../api/api.service'
import { CacheService } from './cache.service'
import { Account } from '../../../../src/app/account/models/account.model'
import { Card } from '../../../../src/app/card/activate/models/card.model'

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  
  constructor(
    public apiService: ApiService,
    public cacheService: CacheService
  ) {
  }

  public async refreshAccountData(): Promise<Account> {
    const account = await this.apiService.getAccount()

    this.cacheService.setSelectedAccount(account)
    this.cacheService.setCardReferenceNumber(account.cardReferenceNumber)

    return account
  }

  public async refreshCardData(): Promise<Card[]> {
    const cards = await this.apiService.getCards()
    this.cacheService.setCards(cards)

    return cards
  }

  public getFormattedAddress(address: Address): string {
    let formattedAddress = ''

    if (address) {
      if (address.street1 && address.street1.trim()) formattedAddress += `${address.street1}`

      if (address.street2 && address.street2.trim()) formattedAddress += `, ${address.street2}`

      if (address.city && address.city.trim()) formattedAddress += `, ${address.city}`

      if (address.stateProvince) formattedAddress += `, ${address.stateProvinceValue}`

      if (address.postalCode && address.postalCode.trim()) formattedAddress += `, ${address.postalCode}`

      if (address.countryCode) formattedAddress += `, ${address.countryCodeValue}`
    }

    return formattedAddress
  }
}
