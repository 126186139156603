import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'mask'
})
export class MaskPipe implements PipeTransform {
  transform(stringToMask: string): string {
    const visibleDigits = 4
    const maskedSection = stringToMask.slice(0, -visibleDigits)
    const visibleSection = stringToMask.slice(-visibleDigits)
    return maskedSection.replace(/./g, '*') + visibleSection
  }
}