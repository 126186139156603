import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  public isDesktop(): boolean {
    return window.innerWidth >= 768
  }
}
