enum TemplaterState {
    InsideTemplate,
    OutsideTemplate,
    TemplateStart
}

interface ITemplaterConfig {
    identifierCharacter: RegExp
    identifierFirstCharacter: RegExp
    templateEnd: RegExp
    templateStart: RegExp
}

class Templater {
    constructor(config: ITemplaterConfig) {
        this._config = config
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    public interpolate(context: Record<string, any>, input: string): string {
        let result = ''
        let state: TemplaterState
        let template: string
        let identifier: string

        for(const char of input) {
            switch (state) {
                case TemplaterState.InsideTemplate:
                    if (this._config.identifierCharacter.exec(char)) {
                        template += char
                        identifier += char
                        result += char
                    } else if (this._config.templateEnd.exec(char)) {
                        template += char
                        result += char

                        state = TemplaterState.OutsideTemplate
                        result = this.replaceTemplate(context, result, template, identifier)
                    } else {
                        state = TemplaterState.OutsideTemplate
                        result += char
                    }
                    break

                case TemplaterState.TemplateStart:
                    if (this._config.identifierFirstCharacter.exec(char)) {
                        state = TemplaterState.InsideTemplate
                        template += char
                        identifier = char
                    } else state = TemplaterState.OutsideTemplate

                    result += char
                    break

                default:    // OutsideTemplate
                    if (this._config.templateStart.exec(char)) {
                        state = TemplaterState.TemplateStart
                        template = char
                    }
                    result += char
                    break
            }
        }

        return result
    }


    private replaceTemplate(context: Record<string, any>, acc: string, template: string, identifier: string): string {
        const value = context[identifier] as string
        return value != null
            ? acc.replace(template, context[identifier])
            : acc
    }

    private _config: ITemplaterConfig
}

export {
    ITemplaterConfig,
    Templater
}