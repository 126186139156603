import { CardStatusEnum, StatusEnum } from './card-status.enum'

export class Card {
  id: string
  partnerId: string
  productId: string
  processorCardId: string | null
  accountNumber: string | null
  status: StatusEnum | null
  statusReasonCode: string | null
  processorCardStatus: string | null
  firstName: string | null
  lastName: string | null
  emailAddress: string | null
  nameOnCard: string | null
  configurationId: string
  category: string | null
  paymentInstrument: string | null
  processor: string | null
  shippingType: string | null
  orderStatus: string | null
  replacementStatus: string | null
  customerId: string | null
  clientIdentifier: string | null
  processorCustomerId: string | null
  panLastFour: string | null
  createdAt: string
  initialActivation: string | null
  activatedAt: string | null
  lastModifiedAt: string
  cardStatus: CardStatusEnum

  public static isAbleToBeActivated(card: Card): boolean {
    if (card.status == StatusEnum.Unactivated &&
      card.orderStatus.toLocaleLowerCase() === 'completed') {
      return true
    }
    return false
  }

  public static getCardType(card: Card): string {
    if (card && card.paymentInstrument && card.paymentInstrument.toLowerCase().indexOf('physical') >= 0)
      return 'Physical Card'
    else
      return 'Virtual Card'
  }
}
