import { Injectable } from '@angular/core'

import { PasswordRule } from './passwordRule.model'

@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  public getPasswordRules(): Promise<PasswordRule[]> {
    return Promise.resolve([
      {
        id: 'min8',
        description: 'Must have a length >= 8',
        pattern: /^.{8,}$/,
        evalWhenNil: true
      },
      {
        id: 'lower',
        description: 'Must contain 1 or more lowercase characters',
        pattern: /^.*[a-z]+.*$/
      },
      {
        id: 'upper',
        description: 'Must contain 1 or more uppercase characters',
        pattern: /^.*[A-Z]+.*$/
      },
      {
        id: 'number',
        description: 'Must contain 1 or more digits',
        pattern: /^.*[0-9]+.*$/
      },
      {
        id: 'special',
        description: 'Must contain 1 or more special characters',
        pattern: /^.*[^a-zA-Z0-9\s]+.*$/
      },
      {
        id: 'restricted-values',
        description: 'Must not contain the username, first name, or last name',
        patterns: [
          '^((?!{username}).)*$',
          '^((?!{firstName}).)*$',
          '^((?!{lastName}).)*$'
        ]
      },
    ])
  }
}
