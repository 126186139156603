class TokenResponseDto {
  email: string
  firstName: string
  lastName: string
}

enum TokenType {
  Registration = 'Registration',
  Forgotpassword = 'Forgotpassword',
  EmailConfirmation = 'EmailConfirmation',
}

export { TokenResponseDto, TokenType }