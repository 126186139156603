<ion-menu contentId="main-content">
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ "commons.menu.menu" | translate }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list>
      <div *ngFor="let item of navigationService.menus; let i = index">
        <ion-item>
          <ion-label *ngIf="item.route || item.action" (click)="navigationService.menuClicked(item); menu.toggle()">{{ item.label
            }}</ion-label>
          <ion-label *ngIf="!item.route && !item.action" (click)="item.active = !item.active">
            <ion-icon name="caret-forward-outline" *ngIf="!item.active"></ion-icon>
            <ion-icon name="caret-down-outline" *ngIf="item.active"></ion-icon>
            {{ item.label }}
          </ion-label>
        </ion-item>
        <ion-item *ngIf="!item.route && item.active">
          <ion-list>
            <ion-item *ngFor="let child of item.children; let j = index"
              (click)="navigationService.menuClicked(child); menu.toggle()" lines="none" detail="false">
              <ion-label>{{ child.label }}</ion-label>
            </ion-item>
          </ion-list>
        </ion-item>
      </div>
    </ion-list>
  </ion-content>
</ion-menu>