/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unused-vars-experimental */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable sonarjs/no-inverted-boolean-check */

import { Component, Inject, OnInit, Renderer2 } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { ModalController } from '@ionic/angular'
import { filter, map } from 'rxjs/operators'
import { IdleMessageComponent } from '../commons/idle-message/idle-message.component'
import { Title } from '@angular/platform-browser'
import { MatDialog } from '@angular/material/dialog'
import { DOCUMENT } from '@angular/common'
import { environment } from '../../src/environments/environment'
import { BrandService } from '../commons/services/ui/brandService.service'
import { UtilsService } from '../../src/commons/services/business/utils.service'
import { AuthWrapperService } from '../../src/security/services/auth'
import { CacheService } from '../../src/commons/services/business/cache.service'
import { ApiService } from '../../src/commons/services/api/api.service'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  alert: any
  appLoaded = false

  constructor(
    public dialog: MatDialog,
    private AuthWrapper: AuthWrapperService,
    public modalController: ModalController,
    private cacheService: CacheService,
    private apiService: ApiService,
    private brandService: BrandService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,

  ) {
    // Use matchMedia to check the user preference
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')

    toggleDarkTheme(prefersDark.matches)

    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches))

    // Add or remove the "dark" class based on if the media query matches
    function toggleDarkTheme(shouldAdd) {
      document.body.classList.toggle('dark', shouldAdd)
    }
  }

  async ngOnInit(): Promise<void> {
    this.AuthWrapper.onInactive.subscribe((): void => {
      if (!this.alert)
        this.alert = this.dialog.open(IdleMessageComponent, { disableClose: true })
    })
    this.AuthWrapper.onActive.subscribe((): void => {
      if (this.alert) {
        this.alert.close()
        this.alert = null
      }
    })

    await this.brandService.setBrand()
    const crbConfig = await this.apiService.getCRBConfig(this.brandService.origin)
    this.cacheService.setCRBConfig(crbConfig)

    void this.validateSession()
    void this.setTitle()
    void this.setFavicon()

    const script = this._renderer2.createElement('script')
    script.id = 'spaceChatModule'
    script.type = 'application/javascript'
    script.src = await this.AuthWrapper.isAuthenticated() ? environment.spaceAuthenticatedChat : environment.spaceChat

    this._renderer2.appendChild(this._document.body, script)

    this.appLoaded = true
  }

  setTitle(): void {
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.route.firstChild
          while (child.firstChild) {
            child = child.firstChild
          }
          return child.snapshot.data['title']
        })
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(ttl)
      })
  }

  public async validateSession(): Promise<void> {
    const isAuthenticated = await this.AuthWrapper.isAuthenticated()
    if (isAuthenticated) {
      this.AuthWrapper.resetIdleTime()
      this.AuthWrapper.start()
    }
  }

  setFavicon() {
    if (UtilsService.favicon) {
      const headElement = document.getElementsByTagName('head')[0]

      const linkElement = document.createElement('link')
      linkElement.rel = 'icon'
      linkElement.type = 'image/png'
      linkElement.href = UtilsService.favicon
      headElement.appendChild(linkElement)
    }
  }

  showMenu = () => !(window.location.href.indexOf('/auth') >= 0) &&
    !(window.location.href.indexOf('/card') >= 0)
}
