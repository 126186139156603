export enum GoogleAnalyticsEvents {
    AccountRegistered = 'account_registered',
    ActivateCard = 'activate_card',
    TransferToBank = 'transfer_to_bank',
    ViewStatement = 'view_statement',
    DownloadStatement = 'download_statement',
    StartChat = 'start_chat',
    Link2Wallet = 'link_to_wallet',
    Logout = 'logout',
    NewPassword = 'password_changed',
    NewPin = 'new_pin',
    NoFunds = 'no_founds',
    CardOrder = 'order_card'
}