<ion-header gaCategory="my_profile_header">
  <ion-item lines="none" class="header-item">
    <ion-button slot="start" (click)="myProfileStep = 0" class="header-buttons"
      *ngIf="myProfileStep === 2 || myProfileStep === 4">
      <ion-icon name="chevron-back"></ion-icon><span>{{ "commons.myProfile.back" | translate }}</span>
    </ion-button>
    <ion-button slot="end" class="header-buttons" (click)="myProfileStep = 0; dismissModal()">
      <ion-icon name="close-outline"></ion-icon>
    </ion-button>
  </ion-item>
  <ion-item lines="none" class="title-item">
    <ion-label>
      {{ "commons.myProfile.title" | translate}}
    </ion-label>
  </ion-item>
</ion-header>
<ion-content gaCategory="my_profile">
  <div class="loading-form" *ngIf="loading">
    <app-loading-placeholder [type]="3"></app-loading-placeholder>
  </div>
  <form [formGroup]="userForm" class="user-form" *ngIf="!loading && myProfileStep === 0">
    <ion-row>
      <ion-col>
        <ion-item lines="none">
          <ion-label class="subtitle">
            {{ "commons.myProfile.userInformation" | translate }}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-item lines="none" class="user-ion-item">
          <ion-label class="user-label">
            {{ "commons.myProfile.name" | translate }}
          </ion-label>
        </ion-item>
        <ion-item lines="none" class="user-ion-item">
          <ion-label class="user-value">
            {{ userDetails.firstName }} {{ userDetails.lastName }}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item lines="none" class="user-ion-item">
          <ion-label class="user-label">
            {{ "commons.myProfile.phoneNumber" | translate }}
          </ion-label>
        </ion-item>
        <ion-item lines="none" class="user-ion-item">
          <ion-label class="user-value">
            {{ userPhone }}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item lines="none" class="user-ion-item">
          <ion-label class="user-label">
            {{ "commons.myProfile.email" | translate }}
          </ion-label>
        </ion-item>
        <ion-item lines="none" class="user-ion-item">
          <ion-label class="user-value">
            {{ userDetails.email }}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item lines="none" class="user-ion-item">
          <ion-label class="user-label">
            {{ "commons.myProfile.address" | translate }}
          </ion-label>
        </ion-item>
        <ion-item lines="none" class="user-ion-item">
          <ion-label class="user-value">
            {{ userAddress }}
          </ion-label>
        </ion-item>
        <ion-item lines="none" class="user-ion-item">
          <ion-label class="user-label update-personal-info-text">
            {{ "commons.myProfile.contactCustomerSupportToUpdateYourPersonalInformation" | translate}}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <div class="divider">
      </div>
    </ion-row>
    <ng-container *ngIf="displayStatementSection">
      <ion-row>
        <ion-col>
          <ion-item lines="none">
            <ion-label class="subtitle ion-no-margin">
              {{ "commons.myProfile.statementDelivery" | translate }}
            </ion-label>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item lines="none" class="item-subtitle-description ion-no-margin">
            <ion-label class="label-subtitle-description ion-no-margin">
              {{ "commons.myProfile.enrollInMail" | translate }}
            </ion-label>
            <ion-toggle color="success" slot="end" formControlName="emailStatement"
              (ionChange)="toggleEmailStatement()"></ion-toggle><br />
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <div class="mfa-box">
            <div class="row highlighted-box">
              <div class="highlighted-box-background"></div>
              <div class="row m-0">
                <ion-label class="label-subtitle-description ion-no-margin">
                  {{ "commons.myProfile.monthlyStatementDescription" | translate }}
                </ion-label>
              </div>
            </div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <div class="divider">
        </div>
      </ion-row>
    </ng-container>
    <ion-row>
      <ion-col>
        <ion-item lines="none">
          <ion-label class="subtitle ion-no-margin">
            {{ "commons.myProfile.authentication" | translate }}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item lines="none" class="item-subtitle-description ion-no-margin">
          <ion-label class="label-subtitle-description ion-no-margin">
            {{ "commons.myProfile.authenticationDescription" | translate }}
          </ion-label>
        </ion-item>
        <ion-item lines="none" class="user-ion-item">
          <ion-label class="user-value">
            {{ "commons.myProfile.yourCurrentMethod" | translate }}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <div class="mfa-box">
      <div class="row highlighted-box">
        <div class="highlighted-box-background"></div>

        <div class="mfa-user-preference-label-block">

          <ion-label class="label-subtitle-description ion-no-margin">

            <ion-icon *ngIf="mfaPreference.userPreferenceValue === 'Email'" name="mail-outline"></ion-icon>
            <span *ngIf="mfaPreference.userPreferenceValue === 'Email'">{{ "commons.myProfile.viaEmail" | translate
              }}</span>

            <ion-icon *ngIf="mfaPreference.userPreferenceValue === 'Sms'" name="chatbubble-ellipses-outline"></ion-icon>
            <span *ngIf="mfaPreference.userPreferenceValue === 'Sms'">{{ "commons.myProfile.viaSms" | translate
              }}</span>

            <ion-icon *ngIf="mfaPreference.userPreferenceValue === 'GoogleAuthenticator'"
              name="phone-portrait-outline"></ion-icon>
            <span *ngIf="mfaPreference.userPreferenceValue === 'GoogleAuthenticator'">{{
              "commons.myProfile.viaGoogleAuthenticator" |
              translate
              }}</span>

            <ion-icon *ngIf="mfaPreference.userPreferenceValue === 'OktaVerify'" name="key-outline"></ion-icon>
            <span *ngIf="mfaPreference.userPreferenceValue === 'OktaVerify'">{{ "commons.myProfile.viaOktaVerify" |
              translate
              }}</span>

            <ion-icon *ngIf="mfaPreference.userPreferenceValue === ''" name="close-circle-outline"></ion-icon>
            <span *ngIf="mfaPreference.userPreferenceValue === ''">{{ "commons.myProfile.notConfigured" | translate
              }}</span>

          </ion-label>

          <div *ngIf="mfaPreference.userPreferenceValue === 'Email' || mfaPreference.userPreferenceValue === 'Sms'">
            <div class="row m-0">
              <ion-label class="label-subtitle-description ion-no-margin w-100">
                <span class="update-mfa-description-value" *ngIf="mfaPreference.userPreferenceValue === 'Email'">{{
                  userDetails.email }}</span>
                <span class="update-mfa-description-value" *ngIf="mfaPreference.userPreferenceValue === 'Sms'">{{
                  utilsService.maskPhoneNumber(userDetails.phoneNumber)
                  }}</span>
              </ion-label>
            </div>
          </div>

        </div>

        <div class="update-button-div">
          <ion-button class="update-button" (click)="goToMfaConfiguration()">
            <ion-icon name="create-outline"></ion-icon>
            <span>
              {{ "commons.myProfile.update" | translate }}
            </span>
          </ion-button>
        </div>

      </div>
    </div>
  </form>
  <form [formGroup]="userForm" class="user-form" *ngIf="!loading && myProfileStep === 2">
    <ion-row>
      <ion-col>
        <ion-item lines="none">
          <ion-label class="subtitle">
            {{ "commons.myProfile.authenticationOptions" | translate }}
          </ion-label>
        </ion-item>
        <ion-item lines="none" class="item-subtitle-description ion-no-margin">
          <ion-label class="label-subtitle-description ion-no-margin">
            {{ "commons.myProfile.authenticationOptionsSubtitle" | translate }}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <div class="mfa-box">
      <div class="row" [ngClass]="newMfaMethod === 'Email' ? 'highlighted-box' : 'box' ">
        <div class="highlighted-box-background"></div>
        <div class="p-0 col-2 checkmark-circle">
          <ion-button class="select-mfa-button" *ngIf="newMfaMethod !== 'Email'" (click)="setMfaPreference('Email')">
          </ion-button>
          <ion-icon name="checkmark-circle" *ngIf="newMfaMethod === 'Email'"></ion-icon>
        </div>
        <div class="m-0 p-0 col-10">
          <div class="row m-0">
            <ion-label class="mfa-title label-subtitle-description ion-no-margin">
              <ion-icon name="mail-outline"></ion-icon>
              <span>{{ "commons.myProfile.viaEmail" | translate }}</span>
            </ion-label>
          </div>
          <div class="row m-0">
            <ion-label class="label-subtitle-description ion-no-margin w-100">
              <span class="update-mfa-description-value">
                {{ "commons.myProfile.viaEmailDescription" | translate }} {{ userDetails.email }}
              </span>
            </ion-label>
          </div>
        </div>
      </div>
    </div>
    <div class="mfa-box">
      <div class="row" [ngClass]="newMfaMethod === 'Sms' ? 'highlighted-box' : 'box' ">
        <div class="highlighted-box-background"></div>
        <div class="p-0 checkmark-circle col-2">
          <ion-button class="select-mfa-button" *ngIf="newMfaMethod !== 'Sms'" (click)="setMfaPreference('Sms')">
          </ion-button>
          <ion-icon name="checkmark-circle" *ngIf="newMfaMethod === 'Sms'"></ion-icon>
        </div>
        <div class="m-0 p-0 col-10">
          <div class="row m-0">
            <ion-label class="mfa-title label-subtitle-description ion-no-margin">
              <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
              <span>{{ "commons.myProfile.viaSms" | translate }}</span>
            </ion-label>
          </div>
          <div class="row m-0">
            <ion-label class="label-subtitle-description ion-no-margin w-100">
              <span class="update-mfa-description-value">
                {{ "commons.myProfile.viaSmsDescription" | translate }} {{
                utilsService.maskPhoneNumber(userDetails.phoneNumber) }}
              </span>
            </ion-label>
          </div>
        </div>
      </div>
    </div>
    <div class="mfa-box">
      <div class="row" [ngClass]="newMfaMethod === 'GoogleAuthenticator' ? 'highlighted-box' : 'box' ">
        <div class="highlighted-box-background"></div>
        <div class="p-0 checkmark-circle col-2">
          <ion-button class="select-mfa-button" *ngIf="newMfaMethod !== 'GoogleAuthenticator'"
            (click)="setMfaPreference('GoogleAuthenticator')">
          </ion-button>
          <ion-icon name="checkmark-circle" *ngIf="newMfaMethod === 'GoogleAuthenticator'"></ion-icon>
        </div>
        <div class="m-0 p-0 col-10">
          <div class="row m-0">
            <ion-label class="mfa-title label-subtitle-description ion-no-margin">
              <ion-icon name="phone-portrait-outline"></ion-icon>
              <span>{{ "commons.myProfile.viaGoogleAuthenticator" | translate }}</span>
            </ion-label>
          </div>
          <div class="row m-0">
            <ion-label class="label-subtitle-description ion-no-margin">
              {{ "commons.myProfile.viaGoogleAuthenticatorDescription" | translate }}
              <strong>{{ "commons.myProfile.next" | translate }}.</strong>
            </ion-label>
          </div>
        </div>
      </div>
    </div>
    <div class="mfa-box">
      <div class="row" [ngClass]="newMfaMethod === 'OktaVerify' ? 'highlighted-box' : 'box' ">
        <div class="highlighted-box-background"></div>
        <div class="p-0 checkmark-circle col-2">
          <ion-button class="select-mfa-button" *ngIf="newMfaMethod !== 'OktaVerify'"
            (click)="setMfaPreference('OktaVerify')">
          </ion-button>
          <ion-icon name="checkmark-circle" *ngIf="newMfaMethod === 'OktaVerify'"></ion-icon>
        </div>
        <div class="m-0 p-0 col-10">
          <div class="row m-0">
            <ion-label class="mfa-title label-subtitle-description ion-no-margin">
              <ion-icon name="key-outline"></ion-icon>
              <span>{{ "commons.myProfile.viaOktaVerify" | translate }}</span>
            </ion-label>
          </div>
          <div class="row m-0">
            <ion-label class="label-subtitle-description ion-no-margin">
              {{ "commons.myProfile.viaOktaVerifyDescription" | translate }}
              <strong>{{ "commons.myProfile.next" | translate }}.</strong>
            </ion-label>
          </div>
        </div>
      </div>
    </div>
  </form>
  <form [formGroup]="mfaValidationForm" class="user-form" *ngIf="!loading && myProfileStep === 4">
    <ion-row>
      <ion-col>
        <ion-item lines="none">
          <ion-label class="subtitle">
            {{ "commons.myProfile.googleAuthenticatorSetup" | translate }}
          </ion-label>
        </ion-item>
        <ion-item lines="none" class="item-subtitle-description ion-no-margin">
          <ion-label class="label-subtitle-description ion-no-margin">
            {{ "commons.myProfile.googleAuthenticatorSetupDescription" | translate }}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item lines="none">
          <img class="qr-code-img" [src]="mfeEnrollment.qrCodeImageUrl">
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item lines="none" class="user-ion-item">
          <ion-label class="user-label">
            {{ "commons.myProfile.code" | translate }}
          </ion-label>
        </ion-item>
        <ion-item lines="none" class="input-user-ion-item">
          <ion-input placeholder="{{  'commons.myProfile.enterCode' | translate }}" class="textfield" type="text"
            formControlName="passCode" [maxlength]="6" appNumber clearOnEdit="false"></ion-input>
        </ion-item>
        <ion-item lines="none" class="validation-message">
          <validation-messages [control]="mfaValidationForm.controls.passCode"></validation-messages>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="mfaError">
      <ion-col>
        <ion-item lines="none">
          <ion-label class="user-label mfa-error">
            {{ "commons.myProfile.mfaErrorCode" | translate }}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </form>
  <form [formGroup]="mfaValidationForm" class="user-form" *ngIf="!loading && myProfileStep === 5">
    <ion-row>
      <ion-col>
        <ion-item lines="none">
          <ion-label class="subtitle">
            {{ "commons.myProfile.smsSetup" | translate }}
          </ion-label>
        </ion-item>
        <ion-item lines="none" class="item-subtitle-description ion-no-margin">
          <ion-label class="label-subtitle-description ion-no-margin">
            {{ "commons.myProfile.smsSetupDescription" | translate }}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item lines="none" class="user-ion-item">
          <ion-label class="user-label">
            {{ "commons.myProfile.code" | translate }}
          </ion-label>
        </ion-item>
        <ion-item lines="none" class="ion-no-margin">
          <ion-input placeholder="{{  'commons.myProfile.enterCode' | translate }}" class="textfield sms-code"
            type="text" formControlName="passCode" [maxlength]="6" appNumber clearOnEdit="false"></ion-input>
        </ion-item>
        <ion-item lines="none" class="validation-message">
          <validation-messages [control]="mfaValidationForm.controls.passCode"></validation-messages>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="mfaError">
      <ion-col>
        <ion-item lines="none">
          <ion-label class="user-label mfa-error">
            {{ "commons.myProfile.mfaErrorCode" | translate }}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </form>
  <form [formGroup]="mfaValidationForm" class="user-form" *ngIf="!loading && myProfileStep === 6">
    <ion-row>
      <ion-col>
        <ion-item lines="none">
          <ion-label class="subtitle">
            {{ "commons.myProfile.oktaVerifySetup" | translate }}
          </ion-label>
        </ion-item>
        <ion-item lines="none" class="item-subtitle-description ion-no-margin">
          <ion-label class="label-subtitle-description ion-no-margin">
            {{ "commons.myProfile.oktaVerifySetupDescription" | translate }}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item lines="none">
          <img class="qr-code-img" [src]="mfeEnrollment.qrCodeImageUrl">
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item lines="none" class="user-ion-item">
          <ion-label class="user-label">
            {{ "commons.myProfile.code" | translate }}
          </ion-label>
        </ion-item>
        <ion-item lines="none" class="input-user-ion-item">
          <ion-input placeholder="{{  'commons.myProfile.enterCode' | translate }}" class="textfield" type="text"
            formControlName="passCode" [maxlength]="6" appNumber clearOnEdit="false"></ion-input>
        </ion-item>
        <ion-item lines="none" class="validation-message">
          <validation-messages [control]="mfaValidationForm.controls.passCode"></validation-messages>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="mfaError">
      <ion-col>
        <ion-item lines="none">
          <ion-label class="user-label mfa-error">
            {{ "commons.myProfile.mfaErrorCode" | translate }}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </form>
  <ion-row class="footer ion-no-margin ion-no-pading">
    <ion-col class="ion-no-margin ion-no-padding">
      <ion-item lines="none" class="ion-no-margin ion-no-pading">
        <div class="info-highlight">

          <div *ngIf="!loading && myProfileStep === 2">
            <ion-button class="action-button" [disabled]="newMfaMethod === mfaPreference.userPreferenceValue"
              (click)="changeMfaPreferences()">
              {{ "commons.myProfile.next" | translate }}
            </ion-button>
          </div>

          <div *ngIf="!loading && myProfileStep === 4">
            <ion-button class="action-button" (click)="activateGoogleAuthenticator()">
              {{ "commons.myProfile.activate" | translate }}
            </ion-button>
          </div>

          <div *ngIf="!loading && myProfileStep === 5">
            <ion-button class="action-button" (click)="activateSmsMfa()">
              {{ "commons.myProfile.activate" | translate }}
            </ion-button>
          </div>

          <div *ngIf="!loading && myProfileStep === 6">
            <ion-button class="action-button" (click)="activateOktaCodeAuthenticator()">
              {{ "commons.myProfile.activate" | translate }}
            </ion-button>
          </div>

        </div>
      </ion-item>
    </ion-col>
  </ion-row>
</ion-content>