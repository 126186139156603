/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Directive, ElementRef, HostListener } from '@angular/core'
import { NgControl } from '@angular/forms'

@Directive({
  selector: '[appNumber]',
})
export class NumberDirective {
  constructor(public ngControl: NgControl, public el: ElementRef) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event)
  }

  onInputChange(event) {
    if (event == null) {
      return ''
    }

    let newVal = event.replace(/\D/g, '')
    if (newVal.length === 0) {
      newVal = ''
    }

    if (newVal === '') {
      this.ngControl.control.setValue(null)
    } else {
      this.ngControl.valueAccessor.writeValue(newVal)
    }
  }
}
