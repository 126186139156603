import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { Component } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { PlatformService } from '../services/business/platform.service'

@Component({
  selector: 'app-html-content-modal',
  templateUrl: './html-content-modal.component.html',
  styleUrls: ['./html-content-modal.component.scss'],
})
export class HtmlContentModalComponent {
  public modalHtmlUrl = ''
  public showCloseButton = true
  public safeResourceUrl: SafeResourceUrl

  constructor(public platform: PlatformService,
    public modalController: ModalController,
    public sanitizer: DomSanitizer) {
    setTimeout(() => {
      this.safeResourceUrl = sanitizer.bypassSecurityTrustResourceUrl(this.modalHtmlUrl)
    }, 100)
  }

  public async dismiss(): Promise<void> {
    const modal = await this.modalController.getTop()
    if (modal) {
      await modal.dismiss()
    }
  }
}
