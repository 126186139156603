import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-loading-placeholder',
  templateUrl: './loading-placeholder.component.html',
  styleUrls: ['./loading-placeholder.component.scss'],
})
export class LoadingPlaceholderComponent {
  @Input() type: PlaceHolderType
}

export enum PlaceHolderType {
  List = 1,
  Form = 2,
  MobileForm = 3
}
