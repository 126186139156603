
export enum CardStatusEnum {
  Unknown = 0,
  Open,
  Closed,
  Revoked,
  LostStolen,
  DepositProhibited,
  AuthorizationProhibited,
  Fraud,
  Chargeoff,
  Preactive
}

export enum StatusEnum {
  Unactivated = 'Unactivated', 
  Active = 'Active', 
  Suspended ='Suspended', 
  Closed = 'Closed'
}
