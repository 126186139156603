import {
    IAppliedPasswordRule,
    ICustomPasswordRule,
    IPatternPasswordRule,
    IPatternCollectionPasswordRule,
    PasswordRule,
    PasswordRuleContext,
    PasswordRuleResultType,
    Template } from './passwordRule.model'

import { Templater } from './templater.model'

type Handler = (result: IAppliedPasswordRule[]) => void

class PasswordRuleEvaluator {
    constructor(handler?: Handler) {
        this._handler = handler
    }

    public addRule(rule: PasswordRule): void {
        this._rules.push(rule)
    }

    public addRules(rules: PasswordRule[]): void {
        this._rules = this._rules.concat(rules)
    }

    public apply(context: PasswordRuleContext): PasswordRuleResultType {
        if  (!this._rules.length) return undefined

        const result: IAppliedPasswordRule[] = this.runRules(context)
        if (this.didResultChange(result)) {
            if (this._handler) this._handler(result)
            this._previousResult = result
        }

        return this.overallResult(result)
    }


    private booleanToPasswordRuleResultType(booleanResult: boolean): PasswordRuleResultType {
        if (booleanResult == null)  return PasswordRuleResultType.NIL
        else return booleanResult ? PasswordRuleResultType.Success : PasswordRuleResultType.Failure
    }

    private didResultChange(currentResult: IAppliedPasswordRule[]) {
        if (!this._previousResult) return true
        if (currentResult.length !== this._previousResult.length) return true

        for (let i = 0; i < currentResult.length; ++i) {
            const currentItem: IAppliedPasswordRule = currentResult[i]
            const previousItem: IAppliedPasswordRule = this._previousResult.find(item => item.id === currentItem.id)
            if (currentItem.result !== previousItem.result) return true
        }

        return false
    }

    private overallResult(result: IAppliedPasswordRule[]): PasswordRuleResultType {
        for (let i = 0; i < result.length; ++i) {
            if (result[i].result === PasswordRuleResultType.Failure) return PasswordRuleResultType.Failure
        }

        return PasswordRuleResultType.Success
    }

    private runCustomRule(context: PasswordRuleContext, rule: PasswordRule): IAppliedPasswordRule {
        const customRule = rule as ICustomPasswordRule
        const customFn = customRule.custom.bind(rule)as (PasswordRuleContext) => boolean | undefined

        return {
            id: rule.id,
            description: rule.description,
            label: rule.tooltip,      // TODO: Translate
            result: this.booleanToPasswordRuleResultType(customFn(context)),
            tooltip: rule.tooltip   // TODO Translate
        }
    }

    private runPatternRule(context: PasswordRuleContext, rule:  PasswordRule): IAppliedPasswordRule {
        const patternRule = rule as IPatternPasswordRule

        const regexp = patternRule.pattern instanceof RegExp
            ? patternRule.pattern
            : new RegExp(this.templateSubstitution(context, patternRule.pattern))

        return this.runPatternsRuleImp(context, rule, [regexp])
    }

    private runPatternsRule(context: PasswordRuleContext, rule:  PasswordRule): IAppliedPasswordRule {
        const regexp = (rule as IPatternCollectionPasswordRule).patterns.map( pattern => {
            return pattern instanceof RegExp
                ? pattern
                : new RegExp(this.templateSubstitution(context, pattern))
        })

        return this.runPatternsRuleImp(context, rule, regexp)
    }

    private runPatternsRuleImp(context: PasswordRuleContext, rule: PasswordRule, regexp: RegExp[]): IAppliedPasswordRule {
        let result: PasswordRuleResultType = PasswordRuleResultType.NIL
        if (context.password || rule.evalWhenNil) {
            for (let i = 0; i < regexp.length; ++i) {
                if (!(regexp[i].exec((context.password ?? '')))) result = PasswordRuleResultType.Failure
            }

            if (result !== PasswordRuleResultType.Failure) result = PasswordRuleResultType.Success
        }

        return {
            id: rule.id,
            description: rule.description,
            label: rule.tooltip,      // TODO: Translate
            result: result,
            tooltip: rule.tooltip   // TODO Translate
        }
    }

    private runRule(context: PasswordRuleContext, rule: PasswordRule): IAppliedPasswordRule {
        if ((rule as ICustomPasswordRule).custom) return this.runCustomRule(context, rule)
        else if ((rule as IPatternPasswordRule).pattern) return this.runPatternRule(context, rule)
        else if ((rule as IPatternCollectionPasswordRule).patterns) return this.runPatternsRule(context, rule)
        else throw new Error('Internal Error: Unable to recognize rule')
    }

    private runRules(context: PasswordRuleContext): IAppliedPasswordRule[] {
        return this._rules.map(rule => this.runRule(context, rule) )
    }

    /*
        Replace the {<name>} items in the template with the corresponding values from the context.
        Only do the replacement if there is a corresponding property.
        (Otherwise, the {...} is most likely a RegExp construct and should not be modified.)
    */
    private templateSubstitution(context: PasswordRuleContext, template: Template): string {
        return this._templater.interpolate(context, template)
    }

    private _handler: Handler
    private _previousResult: IAppliedPasswordRule[]
    private _rules: PasswordRule[] = []

    private _templater = new Templater({
        identifierFirstCharacter: /[$_[a-zA-Z]/,
        identifierCharacter: /[a-zA-Z0-9-_]/,
        templateStart: /{/,
        templateEnd: /}/
    })
}

export {
    PasswordRuleEvaluator
}
