/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AbstractControl, ValidationErrors } from '@angular/forms'
import { IAppliedPasswordRule, PasswordRuleEvaluator } from '../../../src/security/services/passwordRules'
import { UserApiService } from '../../../src/security/services/users/userApi.service'

export class ForgotPasswordValidators {
  public static passwordRuleEvaluator: PasswordRuleEvaluator
  public static appliedPasswordRules: IAppliedPasswordRule[] = []
  public static userApiService: UserApiService
  public static userToken: string

  static passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password: string = control.get('password').value as string
    const confirmPassword: string = control.get('confirmPassword').value as string
    if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors({ NoPassswordMatch: true })
      return { NoPassswordMatch: true }
    }
    else {
      control.get('confirmPassword').setErrors(null)
    }
    return null
  }

  static passwordFieldValidator(control: AbstractControl): ValidationErrors | null {
    const passwordValidationStatus = ForgotPasswordValidators.passwordRuleEvaluator.apply({
      password: control.get('password').value as string,
      username: control.get('userId').value as string,
      firstName: control.get('firstName').value as string,
      lastName: control.get('lastName').value as string,
    })

    if (passwordValidationStatus !== 'Success') {
      for (let ruleIndex = 0; ruleIndex < ForgotPasswordValidators.appliedPasswordRules.length; ruleIndex++) {
        const rule = ForgotPasswordValidators.appliedPasswordRules[ruleIndex]

        if (rule.result === 'Failure') {
          control.get('password').setErrors({
            ...(control.get('password').errors || {}),
            [rule.id]: true,
          })
        }
      }

      return { hasCustomValidationErrors: true }
    }

    return null
  }

  static updateRules(appliedPasswordRules: IAppliedPasswordRule[]): void {
    ForgotPasswordValidators.appliedPasswordRules = appliedPasswordRules
  }
}
