/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Injectable } from '@angular/core'

@Injectable()
export class UtilsService {

  public maskPhoneNumber(phoneNumber: string): string {
    if (!phoneNumber)
      return ''

    let maskedPhone = phoneNumber

    maskedPhone = maskedPhone.replace(/\(/g, '')
    maskedPhone = maskedPhone.replace(/\)/g, '')
    maskedPhone = maskedPhone.replace(/-/g, '')
    maskedPhone = maskedPhone.replace(/ /g, '')

    if (maskedPhone.length === 0) {
      maskedPhone = ''
    } else if (maskedPhone.length <= 3) {
      maskedPhone = maskedPhone.replace(/^(\d{0,3})/, '($1)')
    } else if (maskedPhone.length <= 6) {
      maskedPhone = maskedPhone.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2')
    } else if (maskedPhone.length <= 10) {
      maskedPhone = maskedPhone.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3')
    } else {
      maskedPhone = maskedPhone.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3')
    }

    return maskedPhone
  }

  public static brandedGlobalHeaderColorIsBrightness = false
  public static brandedBottomNavigationColorIsBrightness = false

  public static marketingImages: string[] = []
  public static favicon: string

}