/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { InjectionToken, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouteReuseStrategy } from '@angular/router'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { ApiServiceBase, IApiConfig, ModelMiscModule } from '@sightline/models-misc'
import { CommonsModule } from '../../src/commons/commons.module'
import { environment } from '../../src/environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics'
import { MaterialModule } from '../../src/commons/material/material.module'
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { SwiperModule } from 'swiper/angular'
import { LanguageTranslationService } from '../../src/commons/services/ui/languageTranslation.service'
import { SecurityModule } from '../../src/security/security.module'
import { UserApiService } from '../../src/security/services/users/userApi.service'
import { OKTA_CONFIG } from '../../src/security/services/auth'

const API_CONFIG = new InjectionToken<IApiConfig>('ApiConfig')
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FontAwesomeModule,
    HttpClientModule,
    IonicModule.forRoot(),
    SecurityModule,
    ModelMiscModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false
    }),
    CommonsModule,
    MaterialModule,
    NgxGoogleAnalyticsModule.forRoot(environment.measurementId),
    NgxGoogleAnalyticsRouterModule,
    SwiperModule
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: UserApiService,
      deps: [ApiServiceBase, API_CONFIG],
    },
    ApiServiceBase,
    {
      provide: API_CONFIG,
      useValue: {
        url: environment.apiUrl,
        version: environment.apiVersion,
      },
    },
    {
      provide: OKTA_CONFIG,
      useFactory: () => {
        return {
          clientId: environment.clientId,
          issuer: environment.issuer,
          scopes: environment.scopes,
          pkce: environment.pkce,
        }
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    translateService: LanguageTranslationService,
    library: FaIconLibrary
  ) {
    library.addIconPacks(fas)
    translateService.setLang()
  }
}

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './app/i18n/', `.json?v=${environment.appVersion}`)
}
