<ion-toolbar gaCategory="header_avatar">
  <div class="toolbar">
    <div class="menu" id="main-content">
      <ion-menu-button slot="start" class="menu-button"></ion-menu-button>
    </div>
    <div class="logos">
      <div class="brand-logo" [ngClass]="brandedGlobalHeaderColorIsBrightness ? 'brand-logo-dark' : 'brand-logo-white'">
      </div>
    </div>
    <div class="profile">
      <div class="avatar" (click)="isProfileOpen = !isProfileOpen" id="menu-avatar">
        <ion-icon class="avatar-icon" name="person-circle-outline" *ngIf="!brandedGlobalHeaderColorIsBrightness">
        </ion-icon>
        <ion-icon class="avatar-icon" name="person-circle" *ngIf="brandedGlobalHeaderColorIsBrightness">
        </ion-icon>
      </div>
      <div class="user-info">
        <div class="user-info-text">
          <div class="user-info-name">
            {{ user?.firstName }} {{ user?.lastName }}
          </div>
          <div class="user-info-last-login">
            {{ 'commons.header.lastLogin' | translate }} {{ user?.lastLogin | date: "MM/dd/yyyy" }}
          </div>
        </div>
      </div>
    </div>
    <ion-popover class="popover-items" side="bottom" showBackdrop="false" trigger="menu-avatar" #profilePopover
      (ionPopoverWillPresent)="isProfileOpen = true" (ionPopoverWillDismiss)="isProfileOpen = false">
      <ng-template>
        <div class="item-menu">
          <ion-item (click)="viewProfile()" button="true" lines="none">
            <ion-label>{{ "commons.header.myProfile" | translate }}</ion-label>
          </ion-item>
          <div class="item-line"></div>
        </div>
        <div class="item-menu">
          <ion-item (click)="changePassword()" button="true" lines="none">
            <ion-label>{{ "commons.header.changePassword" | translate }}</ion-label>
            <ion-icon class="popover-icon" name="chevron-forward-circle"></ion-icon>
          </ion-item>
          <div class="item-line"></div>
        </div>
        <div class="item-menu">
          <ion-item button="true" lines="none">
            <ion-label (click)="promptLogout(template)">{{ "commons.header.logOut" | translate }}</ion-label>
          </ion-item>
        </div>
      </ng-template>
    </ion-popover>
  </div>
</ion-toolbar>
<ion-toolbar class="nav-desktop">
  <ng-container *ngFor="let item of navigationService.menus; let i = index">
    <nav *ngIf="(item.children || []).length > 0 || item.route || item.action">
      <ion-label *ngIf="item.route || item.action" (click)="navigationService.menuClicked(item)">{{ item.label
        }}</ion-label>
      <ion-label [id]="'menu' + i" *ngIf="!item.route && !item.action">
        <ion-icon name="caret-forward-outline" *ngIf="!item.active && !item.action"></ion-icon>
        <ion-icon name="caret-down-outline" *ngIf="item.active"></ion-icon>
        {{ item.label }}
      </ion-label>
      <ion-popover [id]="'menuPop' + i" alignment="left" showBackdrop="false" [trigger]="'menu' + i" side="bottom"
        *ngIf="!item.route" class="popover-items" (ionPopoverWillPresent)="item.active = true"
        (ionPopoverWillDismiss)="item.active = false" #submenuPopover>
        <ng-template>
          <div *ngFor="let child of item.children; let j = index" class="item-menu">
            <ion-item (click)="submenuClicked(submenuPopover, child)" lines="none">
              <ion-label>{{ child.label }}</ion-label>
            </ion-item>
            <div class="item-line" *ngIf="j !== item.children.length - 1"></div>
          </div>
        </ng-template>
      </ion-popover>
    </nav>
  </ng-container>
</ion-toolbar>

<ng-template #template>
  <p>{{ "commons.header.logoutMessage" | translate }}</p>
  <div>
    <div class="action" mat-dialog-actions>
      <ion-button class="ion-margin" expand="block" (click)="logout()">
        {{ "commons.header.logOut" | translate }}
      </ion-button>
      <ion-button class="ion-margin" expand="block" color="medium" (click)="closePrompt()">
        {{ "commons.header.cancel" | translate }}
      </ion-button>
    </div>
  </div>
</ng-template>