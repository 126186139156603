/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { AbstractControl, ValidatorFn } from '@angular/forms'
import deepEqual from 'deep-equal'

export class Utils {

  public static deepEqual(object1: unknown, object2: unknown): boolean {
    return deepEqual(object1 || {}, object2 || {})
  }

  public static isNullOrUndefined(object: unknown): boolean {
    return object === null || object === undefined
  }

  public static camelize(str: string): string {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
      if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase()
    })
  }

  public static removeUserPrefix(str: string | null | undefined): string {
    if (str === null || str === undefined || str === '') {
      return ''
    }

    const dashIndex = str.indexOf('-')
    if (dashIndex !== -1) {
      return str.substring(dashIndex + 1)
    }
    return str
  }

  public static addUserPrefix(prefix: string | null | undefined, username: string): string {
    if (prefix)
      return `${prefix}-${username}`
    else
      return username
  }

  public static formatDateToMMDDYYYY(date: Date): string {
    // Extract month, day, and year from the Date object
    const month = (date.getMonth() + 1).toString().padStart(2, '0') // Adding 1 because months are zero-indexed
    const day = date.getDate().toString().padStart(2, '0')
    const year = date.getFullYear().toString()

    // Concatenate the values in the 'MM/DD/YYYY' format
    return `${month}/${day}/${year}`
  }

  public static createDateFromFormatMMDDYYYY(dateString: string): Date | null {
    const dateParts = dateString.split('/')
    if (dateParts.length === 3) {
      const month = parseInt(dateParts[0], 10)
      const day = parseInt(dateParts[1], 10)
      const year = parseInt(dateParts[2], 10)

      // Check if the parsed values are valid
      if (!isNaN(month) && !isNaN(day) && !isNaN(year)) {
        return new Date(year, month - 1, day)
      }
    }
    return null
  }

  static datePatternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const pattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/([0-9]{4})$/
      const isValid = pattern.test(control.value)

      return isValid ? null : { 'invalidDate': true }
    }
  }

  static detectMobileOS(): string {
    const userAgent = navigator.userAgent

    if (/Android/i.test(userAgent)) {
      return 'Android'
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      return 'iOS'
    } else {
      return 'Desktop'
    }
  }

  static isUsingAndroidOrIos(): boolean {
    return this.detectMobileOS() === 'Android' ||
      this.detectMobileOS() === 'iOS'
  }
}