import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { CacheService } from '../services/business/cache.service'
import { Account } from 'src/app/account/models/account.model'
import { Utils } from '../utils/utils'
import { ApiService } from '../services/api/api.service'
import { GoogleAnalyticsEvents } from '../enums/google-analytics-events.enum'

@Component({
  selector: 'app-add-card-to-wallet',
  templateUrl: './add-card-to-wallet.component.html',
  styleUrls: ['./add-card-to-wallet.component.scss'],
})
export class AddCardToWalletComponent implements OnInit {

  account: Account
  walletType = ''
  tokenlink: string

  constructor(
    private modalController: ModalController,
    private cacheService: CacheService,
    public apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.account = this.cacheService.getSelectedAccount()
    this.walletType = Utils.detectMobileOS() === 'iOS' ? 'Apple' : 'Google'
  }

  public async dismissModal(): Promise<void> {
    await this.modalController.dismiss()
  }

  public get googleAnalyticsEvents(): typeof GoogleAnalyticsEvents {
    return GoogleAnalyticsEvents
  }
}
