import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
  providedIn: 'root'
})
export class ValidationMessageService {

  constructor(
    private readonly translate: TranslateService,
  ) { }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getValidatorErrorMessage(validatorName: string, validatorValue?: any, params?: any): string {
    if (validatorValue) {
      return this.translate.instant(`commons.validationMessages.${validatorName}`, params) as string
    }

    return null
  }
}
