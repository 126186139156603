/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable sonarjs/no-duplicate-string */

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { AlertController, IonPopover, LoadingController, ModalController } from '@ionic/angular'
import { PlatformService } from '../../services/business/platform.service'
import { NotificationService } from '../../../../src/commons/services/ui/notification.service'
import { GoogleAnalyticsService } from 'ngx-google-analytics'
import { UtilsService } from '../../../../src/commons/services/business/utils.service'
import { CacheService } from '../../../../src/commons/services/business/cache.service'
import { Account } from '../../../../src/app/account/models/account.model'
import { AccountsService } from '../../../../src/commons/services/business/accounts.service'
import { TranslateService } from '@ngx-translate/core'
import {
  ActivateMfaRequestDto,
  FactorEnrolledResponseDto,
  MfaType,
  OktaFactorType,
  OktaProvider,
  UserFactorDto,
  UserInfoDto,
  UserPreferenceDto,
  UserPreferenceKey
} from '../../../../src/security/models'
import { UserApiService } from '../../../../src/security/services/users/userApi.service'
import { PatronPreferenceDto } from '../../../../src/commons/services/api/models/patron-preference'
import { ApiService } from '../../../../src/commons/services/api/api.service'
import { PatronPreferences } from '../../../../src/commons/enums/patron-preferences.enum'
import { TitleService } from '../../../../src/commons/services/ui/title.service'
import { Title } from '@angular/platform-browser'

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
  public userForm: FormGroup
  public mfaValidationForm: FormGroup
  public domains: string[]
  loading = true

  public myProfileStep = MyProfileSteps.ViewInformation
  public displayStatementSection = true

  private googleAnalyticsCategory = 'my_profile'

  @ViewChild('popoverDom') popoverDom: IonPopover
  @ViewChild('successMessage', { read: ElementRef }) successMessage: ElementRef
  userDetails = new UserInfoDto()
  userAccount: Account
  userPhone = ''
  userAddress = ''
  callerTitle: string

  //mfa properties
  mfaPreference: UserPreferenceDto = { userPreferenceKey: UserPreferenceKey.MFA_PREFERRED_METHOD, userPreferenceValue: MfaType.Email } as UserPreferenceDto
  newMfaMethod: MfaType | string
  factors: UserFactorDto[]
  mfaError = false
  mfeEnrollment: FactorEnrolledResponseDto
  loadingModal: HTMLIonModalElement
  loadingMessage: HTMLIonLoadingElement
  patronPreferences: Array<PatronPreferenceDto>

  constructor(
    private modalController: ModalController,
    private loadingController: LoadingController,
    public platform: PlatformService,
    public userApiService: UserApiService,
    public apiService: ApiService,
    public notificationService: NotificationService,
    public utilsService: UtilsService,
    public cacheService: CacheService,
    public accountsService: AccountsService,
    public translateService: TranslateService,
    public alertController: AlertController,
    protected $gaService: GoogleAnalyticsService,
    private readonly translate: TranslateService,
    private readonly titleAppService: TitleService,
    private readonly titleService: Title,
  ) {
    this.userForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      phoneNumber: new FormControl('', [Validators.required, Validators.pattern(new RegExp(/(\(\d{3}\)) (\d{3})-(\d{4})/))]),
      street1: new FormControl('', Validators.required),
      street2: new FormControl(''),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      zipCode: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{5}$')]),
      emailStatement: new FormControl(false) 
    })
  }

  public async ngOnInit(): Promise<void> {
    this.loading = true
    this.callerTitle = this.titleService.getTitle()
    this.titleAppService.setTitle(this.translate.instant('commons.header.myProfile'))
    this.userDetails = await this.cacheService.getUserInfo()

    const userPreferences = await this.userApiService.getUserPreferences(
      [UserPreferenceKey.MFA_PREFERRED_METHOD] as Array<UserPreferenceKey>
    )
    if (userPreferences && userPreferences.length > 0)
      this.mfaPreference = userPreferences[0]

    this.userAccount = this.cacheService.getSelectedAccount()
    this.userPhone = this.utilsService.maskPhoneNumber(this.userDetails.phoneNumber)
    this.userAddress = this.accountsService.getFormattedAddress(this.userAccount?.address)

    try {
      this.patronPreferences = await this.apiService.getPatronPreferences(this.userAccount.cardReferenceNumber)
      this.displayStatementSection = true
    }
    catch {
      this.displayStatementSection = false
    }

    this.userForm.get('firstName').setValue(this.userDetails.firstName)
    this.userForm.get('lastName').setValue(this.userDetails.lastName)
    this.userForm.get('email').setValue(this.userDetails.email)
    this.userForm.get('phoneNumber').setValue(this.utilsService.maskPhoneNumber(this.userDetails.phoneNumber))
    this.userForm.get('street1').setValue(this.userAccount?.address?.street1)
    this.userForm.get('street2').setValue(this.userAccount?.address?.street2)
    this.userForm.get('city').setValue(this.userAccount?.address?.city)
    this.userForm.get('state').setValue(this.userAccount?.address?.stateProvinceValue)
    this.userForm.get('zipCode').setValue(this.userAccount?.address?.postalCode)

    const emailStatement = this.patronPreferences?.find(p => p.preferenceType == PatronPreferences.PaperStatement || p.preferenceType == null)?.isEnabled || false

    this.userForm.get('emailStatement').setValue(emailStatement)

    //register GA event
    this.$gaService.event('my_profile_access', this.googleAnalyticsCategory)

    this.loading = false
  }

  public async changeMfaPreferences(): Promise<void> {

    if (!this.factors)
      return

    switch (this.newMfaMethod) {
      case MfaType.Email:
        await this.setUserMfaPreference(MfaType.Email)

        break
      case MfaType.GoogleAuthenticator:
        await this.setUserGoogleAuthenticatorMfaPreference()

        break
      case MfaType.Sms:
        await this.setUseSmsMfaPreference()

        break
      case MfaType.OktaVerify:
        await this.setOktaVerifyMfaPreference()

        break
      default:
        break
    }
  }

  async goToGoogleAuthenticatorSetup(): Promise<void> {
    try {
      this.loading = true
      this.mfaError = false
      this.mfeEnrollment = await this.userApiService.postMfaEnroll({ type: MfaType.GoogleAuthenticator })

      this.mfaValidationForm = new FormGroup({
        passCode: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{6}$')]),
      })

      this.myProfileStep = MyProfileSteps.GoogleAuthenticationSetup

    }
    catch (e) {
      console.log(e)
      void this.notificationService.showUnhandledToasterErrorMessage()
      this.myProfileStep = MyProfileSteps.ViewInformation
    }
    finally {
      this.loading = false
    }
  }

  async activateGoogleAuthenticator(): Promise<void> {
    try {

      if (!this.mfaValidationForm.valid) {
        this.mfaError = true
        return
      }

      void this.presentLoadingMessage()
      const payload = {
        type: MfaType.GoogleAuthenticator,
        oktaUserId: this.mfeEnrollment.oktaUserId,
        factorId: this.mfeEnrollment.enrolledFactorId,
        passCode: this.mfaValidationForm.get('passCode').value
      } as ActivateMfaRequestDto

      const result = await this.userApiService.postMfaActivate(payload)

      if (result) {
        await this.setUserMfaPreference(MfaType.GoogleAuthenticator)
      } else {
        this.mfaError = true
      }
    }
    catch (e) {
      console.log(e)
      this.mfaError = true
    }
    finally {
      void this.dismissLoadingMessage()
    }
  }

  async goToSmsMfaSetup(): Promise<void> {
    try {
      this.loading = true
      this.mfaError = false
      this.mfeEnrollment = await this.userApiService.postMfaEnroll({ type: MfaType.Sms })

      this.mfaValidationForm = new FormGroup({
        passCode: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{6}$')]),
      })

      this.myProfileStep = MyProfileSteps.SmsMfaSetup
    }
    catch (e) {
      console.log(e)
      void this.notificationService.showUnhandledToasterErrorMessage()
      this.myProfileStep = MyProfileSteps.ViewInformation
    }
    finally {
      this.loading = false
    }
  }

  async activateSmsMfa(): Promise<void> {
    try {

      if (!this.mfaValidationForm.valid) {
        this.mfaError = true
        return
      }

      void this.presentLoadingMessage()
      const payload = {
        type: MfaType.Sms,
        oktaUserId: this.mfeEnrollment.oktaUserId,
        factorId: this.mfeEnrollment.enrolledFactorId,
        passCode: this.mfaValidationForm.get('passCode').value
      } as ActivateMfaRequestDto

      const result = await this.userApiService.postMfaActivate(payload)

      if (result) {
        await this.setUserMfaPreference(MfaType.Sms)
      } else {
        this.mfaError = true
      }
    }
    catch (e) {
      console.log(e)
      this.mfaError = true
      this.myProfileStep = MyProfileSteps.SmsMfaSetup
    }
    finally {
      void this.dismissLoadingMessage()
    }
  }

  async goToOktaCodeAuthenticatorSetup(): Promise<void> {
    try {
      this.loading = true
      this.mfaError = false
      this.mfeEnrollment = await this.userApiService.postMfaEnroll({ type: MfaType.OktaVerify })

      this.mfaValidationForm = new FormGroup({
        passCode: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{6}$')]),
      })

      this.myProfileStep = MyProfileSteps.OktaVerifyCodeSetup

    }
    catch (e) {
      console.log(e)
      void this.notificationService.showUnhandledToasterErrorMessage()
      this.myProfileStep = MyProfileSteps.ViewInformation
    }
    finally {
      this.loading = false
    }
  }

  async activateOktaCodeAuthenticator(): Promise<void> {
    try {

      if (!this.mfaValidationForm.valid) {
        this.mfaError = true
        return
      }

      void this.presentLoadingMessage()
      const payload = {
        type: MfaType.OktaVerify,
        oktaUserId: this.mfeEnrollment.oktaUserId,
        factorId: this.mfeEnrollment.enrolledFactorId,
        passCode: this.mfaValidationForm.get('passCode').value
      } as ActivateMfaRequestDto

      const result = await this.userApiService.postMfaActivate(payload)

      if (result) {
        await this.setUserMfaPreference(MfaType.OktaVerify)
      } else {
        this.mfaError = true
      }
    }
    catch (e) {
      console.log(e)
      this.mfaError = true
    }
    finally {
      void this.dismissLoadingMessage()
    }
  }

  async goToMfaConfiguration(): Promise<void> {
    try {
      this.loading = true
      this.myProfileStep = MyProfileSteps.UpdateMfaPreferences
      this.newMfaMethod = this.mfaPreference.userPreferenceValue
      this.factors = await this.userApiService.getMfaFactors()
    } catch (e) {
      console.log(e)
      void this.notificationService.showUnhandledToasterErrorMessage()
      this.myProfileStep = MyProfileSteps.ViewInformation
    }
    finally {
      this.loading = false
    }
  }

  public setMfaPreference(method: string): void {
    this.newMfaMethod = method as MfaType
  }

  async toggleEmailStatement() : Promise<void> {
    try
    {
      void await this.apiService.updatetPatronPreference(this.userAccount.cardReferenceNumber, {
        preferenceType: PatronPreferences.PaperStatement,
        isEnabled: this.userForm.value.emailStatement
      })

      void this.notificationService.showToasterMessage({
        message: this.translateService.instant('commons.myProfile.emailStatementConfigurationSuccess') as string,
      })
    }
    catch (e) {
      console.log(e)
      void this.notificationService.showUnhandledToasterErrorMessage()
    }
    finally {
      this.loading = false
    }
  }

  public async dismissModal(): Promise<void> {
    this.titleAppService.setTitle(this.callerTitle, false)
    await this.modalController.dismiss()
  }

  private async presentLoadingMessage() {
    this.loadingMessage = await this.loadingController.create({
      message: 'Please wait...',
    })

    await this.loadingMessage.present()
  }

  private async dismissLoadingMessage() {
    await this.loadingMessage.dismiss()
  }

  private async createMfaAuthenticatorAlert(mfaType: MfaType): Promise<HTMLIonAlertElement> {
    return await this.alertController.create({
      mode: 'md',
      cssClass: 'confirmation-prompt',
      header: this.translateService.instant('commons.myProfile.mfaMethodAlreadyConfigured') as string,
      subHeader: this.translateService.instant('commons.myProfile.mfaMethodAlreadyConfiguredDescription') as string,
      inputs: [
        {
          type: 'radio',
          label: this.translateService.instant(
            'commons.myProfile.setItAsMypreferedMethod'
          ) as string,
          value: 'setItAsMypreferedMethod',
          checked: true
        },
        {
          type: 'radio',
          label: this.translateService.instant(
            'commons.myProfile.startANewMfaConfiguration'
          ) as string,
          value: 'startANewMfaConfiguration'
        }
      ],
      buttons: [
        {
          text: this.translateService.instant(
            'commons.myProfile.cancel'
          ) as string,
        },
        {
          text: this.translateService.instant(
            'commons.myProfile.continue'
          ) as string,
          handler: async (data) => {
            switch(data)
            {
              case 'setItAsMypreferedMethod':
                await this.setUserMfaPreference(mfaType)
                break
              case 'startANewMfaConfiguration':
                if (mfaType === MfaType.GoogleAuthenticator) {
                  void await this.goToGoogleAuthenticatorSetup()
                }
                else if (mfaType === MfaType.OktaVerify) {
                  void await this.goToOktaCodeAuthenticatorSetup()
                }
                else {
                  void this.notificationService.showUnhandledToasterErrorMessage()
                  this.myProfileStep = MyProfileSteps.ViewInformation
                }
                break
            }
          }
        },
      ],
    })
  }

  private async setUserMfaPreference(mfaType: MfaType) {
    try {
      this.loading = true
      const userPreference = { userPreferenceKey: UserPreferenceKey.MFA_PREFERRED_METHOD, userPreferenceValue: mfaType } as UserPreferenceDto
      await this.userApiService.postUserPreferences([userPreference])
      this.mfaPreference = userPreference
      this.myProfileStep = MyProfileSteps.ViewInformation

      void this.notificationService.showToasterMessage({
        message: this.translateService.instant('commons.myProfile.mfaConfigurationSuccess') as string,
      })
    }
    catch {
      void this.notificationService.showUnhandledToasterErrorMessage()
    }
    finally {
      this.loading = false
    }
  }

  private async setOktaVerifyMfaPreference() {
    if (this.factors.findIndex(q => q.provider == OktaProvider.Okta && q.factorType == OktaFactorType.TokenSoftwareTotp) >= 0) {
      const alert = await this.createMfaAuthenticatorAlert(MfaType.OktaVerify)
      await alert.present()
    } else {
      await this.goToOktaCodeAuthenticatorSetup()
    }
  }

  private async setUseSmsMfaPreference() {
    const factor = this.factors.find(q => q.provider == OktaProvider.Okta && q.factorType == OktaFactorType.Sms)

    if (factor && factor.factorData && factor.factorData['sms'] == this.userDetails.phoneNumber) {
      await this.setUserMfaPreference(MfaType.Sms)
    } else {
      void this.goToSmsMfaSetup()
    }
  }

  private async setUserGoogleAuthenticatorMfaPreference() {
    if (this.factors.findIndex(q => q.provider == OktaProvider.Google) >= 0) {
      const alert = await this.createMfaAuthenticatorAlert(MfaType.GoogleAuthenticator)
      await alert.present()
    } else {
      await this.goToGoogleAuthenticatorSetup()
    }
  }
}

export enum MyProfileSteps {
  ViewInformation = 0,
  // EditInformation = 1,
  UpdateMfaPreferences = 2,
  UploadDocuments = 3,

  GoogleAuthenticationSetup = 4,
  SmsMfaSetup = 5,
  OktaVerifyCodeSetup = 6
}
