<ion-header gaCategory="change_password_header">
  <ion-item lines="none" class="header-item">
    <ion-button slot="end" class="header-buttons" (click)="dismissModal()">
      <ion-icon name="close-outline"></ion-icon>
    </ion-button>
  </ion-item>
  <ion-item lines="none" class="title-item">
    <ion-label>
      {{ "commons.changePassword.title" | translate}}
    </ion-label>
  </ion-item>
</ion-header>
<ion-content gaCategory="change_password">
  <app-password-change></app-password-change>
</ion-content>